<template>
  <div>
    <vue-flux
      :options="options"
      :images="images"
      :captions="captions"
      :transitions="transitions"
      @transition-end="sliderChanged"
      ref="slider"
      class="flux-slider-custom-class"
    >
      <template v-slot:caption>
        <flux-caption v-slot="captionProps">
          <p v-if="captionProps.text" class="content">{{ captionProps.text }}</p>
        </flux-caption>
      </template>
    </vue-flux>
  </div>
</template>

<script>
import store from "../store/index";
import { VueFlux, FluxCaption } from "vue-flux";
import utils from "../shared/utils";
export default {
  name: "background-multi-image",
  props: ["data"],
  components: {
    VueFlux,
    FluxCaption
  },
  methods: {
    sliderChanged() {
      this.sliderNo += 1;
      if (!this.destroyed && this.sliderNo > this.model.files.length)
        store.dispatch("setNextPlaylist");
    }
  },
  data() {
    return {
      sliderNo: 1,
      destroyed: false,
      options: {
        autoplay: true
      },
      transitions: utils.shuffleArray([
        // "blinds3d",
        // "blocks2",
        // "book",
        // "cube",
        // "round2",
        "swipe",
        // "warp",
        // "wave",
        // "zip",
        // "waterfall",
        "kenburn",
        "camera",
        // "fall",
        "fade",
        // "explode",
        // "blocks1",
        "slide"
        // "concentric",
        // "blinds2d",
        // "round1"
      ])
    };
  },
  computed: {
    model() {
      return this.$store.state.backgroundMultiImage;
    },
    images() {
      return this.model.files.map(
        file => `${process.env.VUE_APP_BASE_CDN_URL}/${file.fileName}`
      );
    },
    captions() {
      return this.model.files.map(file => `${file.title || " "}`);
    }
  },
  beforeDestroy() {
    this.destroyed = true;
  }
};
</script>

<style lang="scss" scoped>
.content {
  width: 100%;
  height: 100%;
  padding: 0;
  position: absolute;
  top: 0px;
  color: #f1f1f1;
  font-size: 72px;
}
</style>
<style>
.flux-slider-custom-class {
  height: 100%;
  width: 100%;
  object-fit: contain;
  position: absolute;
}

.flux-image {
  background-size: 100% 100% !important;
  background-position: center center !important;
}
</style>
